import GeoJSON from "ol/format/GeoJSON";
import Feature from "ol/Feature";
import { Geometry } from "ol/geom";
import React, { useCallback, useMemo } from "react";
import { RFeature, RLayerVector, RStyle, RPopup } from "rlayers";
import { Layer } from "../../config/interfaces";
import { InfoWindowWihoutMarker } from "./InfowindowCreatorWithoutMarker";

interface MapPolygonCreatorInterface {
  layer: Layer;
}

export function MapPolygonCreator(layer_prop: MapPolygonCreatorInterface) {

  const layer = layer_prop.layer;

  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=1000&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });

  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { name: string, type: string, color_hex: string } }[];
  };
  const fetchData = useMemo(() => {
    return fetch(layer_url).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url]);

  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);
  RFeature.hitTolerance = 0;

  const [data, setData] = React.useState({ features: [] } as inputDataType);

  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );

  const getLineColor = (f: any) => {
    let color = '#99d606';
    return (
        <>
        <RStyle.RFill color={color}/>
        <RStyle.RStroke width={1} color={'#f5f5f5'}/>
        </>
        ); 
  };
  
  const getPolygonColor = (f: any) => {
    let color = f.values_.color_hex;
    if (!color) {
        color = "rgba(255, 255, 255, 0.2)";
    }
    else {
        color = f.values_.color_hex.trim();
    }
    return (<>
    <RStyle.RFill color={color}/>
    <RStyle.RStroke width={2} color={"#373737"}/>
    </>
    ); 
  }

  return (
    <>
    <RLayerVector
        zIndex={2}
        format={parser}
        url={layer_url}
        visible={false}
      >
        <RLayerVector
        zIndex={1}
        format={parser}
        url={layer_url}
        onPointerEnter={useCallback((e: any) => setCurrent(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent(null),
          [current]
        )}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (getLineColor(f)),
            []
          )}
        />
      </RLayerVector>
      </RLayerVector>

      { layer.infowindow ? 
      <RLayerVector zIndex={5}>
        {current ? (
          (<div>
            <RFeature geometry={current.getGeometry()}>
              <RPopup trigger="hover"
                positioning="center-left">
                  <InfoWindowWihoutMarker feature={current.getProperties()} infowindow={layer.infowindow}/>
              </RPopup>
            </RFeature>
          </div>)
        ) : null}
      </RLayerVector> : null }
    </>
  );
}
