import BaseStyles from "../components/common.module.scss";
import Navbar from "../components/Navbar/Navbar";
import PageContainer from "../components/PageContainer/PageContainer";
import RegistrationWindow from "../components/RegistrationWindow/RegistrationWindow";

export default function RegPage() {
  return (
    <>
      <div className={BaseStyles.splashScreenOpacity} />
      <Navbar activePage={"Авторизация"} />
      <PageContainer>
        <RegistrationWindow />
      </PageContainer>
    </>
  );
}
