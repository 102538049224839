import React from "react";
import styles from "./PopUp.module.scss";
import Button from "../Button/Button";
import ImageBlock from "../ImageBlock/ImageBlock";
import classNames from "classnames";
import { measureTextWidth, measureTextBlockWidth } from "../../common/utils";

export type PopUpProps = {
  popupData: {
    /** Заголовок, если он один, то контейнер будет меньше и цвет заголовка черный */
    title: string;
    /** Подзаголовок */
    subTitle?: string | number;
    /** Подпись */
    description?: string;
    /** Основной текст */
    paragraph?: string;
    /** Изображение */
    image?: { url: string };
    /** Список с параметрами */
    list?: { title: string; value?: string }[];
    /** Подпись в конце, может отображать ссылку  */
    subscription?: string;
    /** Делает цвет подзаголовка синим, а заголовка черный  */
    colorSubTitle?: boolean;
    /** Название кнопки, по-умолчания "Подробнее"*/
    buttonTitle?: string;
    /** Делает ширину попапа 180 пикселей и уменьшает отступы */
    smallPopup?: boolean;
  };
  onClick?: () => void;
};

const PopUp: React.FC<PopUpProps> = ({ popupData, onClick }) => {
  const {
    title,
    subTitle,
    description,
    paragraph,
    image,
    list,
    subscription,
    colorSubTitle,
    buttonTitle,
    smallPopup,
  } = popupData;

  const onlyTitle =
    !subTitle && !description && !paragraph && !image && !list && !subscription;

  const titleFontStyles = "700 20px Nunito Sans";

  const titleWidth = onlyTitle
    ? measureTextBlockWidth(title, titleFontStyles, 316, 22)
    : "";

  const transformSubtitle = (subtitle: string | number): string => {
    if (typeof subtitle === "number") {
      return subtitle.toLocaleString("ru-RU");
    }
    const parsedSubtitle =
      typeof subtitle === "string"
        ? Number(subtitle.replace(/\s/g, "").replace(/,/g, "."))
        : subtitle;
    if (isNaN(parsedSubtitle)) {
      return subtitle;
    }
    return parsedSubtitle.toLocaleString("ru-RU");
  };

  const formatSubtitle =
    subTitle !== undefined ? transformSubtitle(subTitle) : undefined;

  const hasProtocol = subscription
    ? /^(https?|http):\/\//i.test(subscription)
    : false;

  const siteAddress = hasProtocol ? "Перейти на сайт" : "";

  const listValueFontStyles = "700 15px Nunito Sans";

  const listValueWidth = (() => {
    const widths: number[] = [];
    list?.forEach((item) => {
      item.value &&
        widths.push(measureTextWidth(item.value, listValueFontStyles));
    });
    const maxWidth = Math.max(...widths);
    return maxWidth > 100 ? 100 : maxWidth;
  })();

  return (
    <div
      className={classNames(styles.container, {
        [styles.small]: smallPopup || onlyTitle,
        [styles.onlyTitle]: onlyTitle,
      })}
    >
      <div className={styles.titlesContainer}>
        <div
          className={classNames(styles.title, {
            [styles.noColorTitle]: colorSubTitle || onlyTitle,
          })}
          style={{ maxWidth: titleWidth }}
        >
          {title.trim()}
        </div>
        {formatSubtitle && (
          <div
            className={classNames(styles.subTitle, {
              [styles.subTitleBlue]: colorSubTitle,
            })}
          >
            {formatSubtitle}
          </div>
        )}
        {description && <div className={styles.description}>{description}</div>}
      </div>
      {list && !image && (
        <div className={styles.listContainer}>
          {list.map((item, index) => (
            <div key={index} className={styles.itemContainer}>
              <div style={{ width: 250 - listValueWidth }}>{item.title}</div>
              <div
                className={styles.itemValue}
                style={{ width: listValueWidth }}
              >
                {item.value}
              </div>
            </div>
          ))}
        </div>
      )}
      {paragraph && !image && <p className={styles.paragraph}>{paragraph}</p>}
      {image && <ImageBlock contentBlock={image} key={1} />}

      {subscription &&
        (hasProtocol ? (
          <a
            href={subscription}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.subscriptionLink}
          >
            {siteAddress}
          </a>
        ) : (
          <div className={styles.subscription}>{subscription}</div>
        ))}
      {onClick && (
        <Button
          title={buttonTitle ?? "Подробнее"}
          style={{ marginTop: 10 }}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default PopUp;
