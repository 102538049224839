import { Marker, PieDataInterface } from "../../config/interfaces";
import {
    SimpleRectangle, SimpleRectangleSelectedLabel
} from "../../elements/diagrams";

interface MarkerCreatorInterface {
    marker: Marker,
    feature: any //fix it!
}

export function MarkerCreator(props: MarkerCreatorInterface) {
        const f = props.feature;
        const marker = props.marker;

        let color = "#000000";
        if (marker.value_color) {
            color = marker.value_color
        }

        const value2number = (value: string | number) => {
          if (typeof value === 'string') {
            return Number(value.replace(',', '.'))
          }
          return value
        }

        const create_rectangle_value_diagramm = (marker: Marker, f: any) => {
          if (marker.value_field) {
            let width = f.properties[marker.width];
            let height = f.properties[marker.height];
            let size_multiplication = 1;
            if (marker.size_multiplication) {
              size_multiplication = marker.size_multiplication;
            }
            return (
              <SimpleRectangle
                feature={f}
                value={value2number(marker.value_field)} 
                width={value2number(width) * size_multiplication} height={value2number(height) * size_multiplication}  
                color={'#ffffff'} 
                background={f.properties[marker.background_color]}
                minValue={0}/>
            );
          }
          else {
            return null;
          }
        }

        switch (marker.type) {
          case "simple_rect":
            return (
                <SimpleRectangle 
                    feature={f}
                    value={0} 
                    width={value2number(marker.width)} height={value2number(marker.height)} 
                    color={color} 
                    background={f.properties[marker.background_color]}
                    minValue={0}/>
              );  
            break;
          case "simple_rect_value":
            return (
                create_rectangle_value_diagramm(marker, f)
                );  
            break;
          default:
            return (
                <SimpleRectangle 
                    feature={f}
                    value={0} 
                    width={16} height={16} 
                    color={'#ffffff'} 
                    background={'#000000'}
                    minValue={0}/>
              );  
        }
    
}
