import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setModalState } from "../../../../redux/modal";
import PopUp from "../../../PopUp/PopUp";
import { InfoWindow } from "../../config/interfaces";
import { getPopupData } from "../../utils";

interface InfowindowCreatorInterface {
    infowindow: InfoWindow,
    feature: any, //fix it!
}

export const InfoWindowWihoutMarker = (props: InfowindowCreatorInterface) => {

  const [isShown, setIsShown] = useState(false);

  const infowindow = props.infowindow;
  const feature = props.feature;

  const popupData = getPopupData(infowindow, feature);


  const dispatch = useDispatch();

  const handleModalOpen = (title: string, text: string) => {
    dispatch(
      setModalState({
        isVisible: true,
        title: title,
        content: <p> {text} </p>,
      })
    );
  };

  
  const readMore = () => {
    if (infowindow.modal_paragraph) {
        handleModalOpen(feature.properties[infowindow.title], feature.properties[infowindow.modal_paragraph]);
    }
    else {
      handleModalOpen('', '');
    }
  }
  

  return (
    <div>
      {infowindow.modal ?
      <PopUp popupData={popupData} onClick={readMore} />
      :
      <PopUp popupData={popupData} />
      }
    </div>
  );
};
