import { memo, useState } from "react";
import styles from "./LayerElement.module.scss";
import Checkbox from "../Checkbox/Checkbox";
import ButtonPlusMinus from "../ButtonPlusMinus/ButtonPlusMinus";
import { ContentBlockByType } from "../DataBlocks/DataBlocks";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  removeAbstractLayerFromWorkSpace,
  toggleMultipleLayersVisible,
} from "../../redux/map";
import { AbstractLayer } from "../../pages/ConstructorPage";

function getLayerIds(layer: AbstractLayer) {
  return layer.map_layers.reduce((acc: string[], cur) => {
    acc.push(cur.layer);
    return acc;
  }, []);
}

export default memo(function LayerElement({ layer }: { layer: AbstractLayer }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const layerIds = getLayerIds(layer);

  const layers = useSelector(
    (state: RootState) => state.map.map_config?.layers
  );

  const checked =
    layers
      ?.filter((layer) => layerIds.includes(layer.layer))
      .every((layer) => layer.visible === true) ?? false;

  const handleOnContextMenuClick = (id: string) => {
    dispatch(removeAbstractLayerFromWorkSpace(id));
  };

  return (
    <div
      className={styles.elemItem}
      onContextMenu={
        handleOnContextMenuClick
          ? (e) => {
              e.preventDefault();
              handleOnContextMenuClick(layer.post_title);
            }
          : undefined
      }
    >
      <div className={styles.elementContainer}>
        <ButtonPlusMinus isOpen={isOpen} toggleOpen={toggleDropdown} />
        <Checkbox
          checked={checked}
          setChecked={(value) =>
            dispatch(
              toggleMultipleLayersVisible({
                layerIds,
                value,
              })
            )
          }
          style={{ marginRight: "4px" }}
        />
        <div className={styles.titleContainer}>
          <div className={styles.title}> {layer.post_title}</div>
          <div className={styles.chapter}> {layer.chapter_title}</div>
        </div>
      </div>

      {isOpen && (
        <div style={{ paddingTop: 12 }}>
          <ContentBlockByType contentBlock={layer.content_block} />
        </div>
      )}
    </div>
  );
});
