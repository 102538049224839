import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Block } from "../../../types/content-types";
import ContentBlockBase from "../../ContentBlockBase";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Linear = ({ contentBlock }: { contentBlock: Block }) => {
  const { x_legend, x_data } = contentBlock;
  const data = {
    labels: x_legend,
    datasets: [
      {
        label: "",
        data: x_data,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  return (
    <ContentBlockBase contentBlock={contentBlock}>
      <Bar data={data} />
    </ContentBlockBase>
  );
};

export default Linear;
