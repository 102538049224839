import React, { useState } from "react";
import styles from "./RegistrationWindow.module.scss";
import Button from "../Button/Button";

import { useNavigate } from "react-router-dom";
import { userSignUp } from "../../auth/AuthApiService";
import { IUserSignUpData } from "../../types/user";
import { TextField } from "../Input/TextField/TextField";

const RegistrationWindow: React.FC = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    login: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const registration = async () => {
    const validationErrors: Record<string, string> = {};

    if (credentials.login.trim() === "") {
      validationErrors.login = "Необходимо ввести логин";
    }

    if (
      !credentials.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)
    ) {
      validationErrors.email = "Invalid email address";
    }

    if (credentials.password.length < 8) {
      validationErrors.password =
        "Пароль должен состоять минимум из 8 символов";
    }

    if (Object.keys(validationErrors).length === 0) {
      const signUpData: IUserSignUpData = {
        login: credentials.login,
        email: credentials.email,
        password: credentials.password,
      };
      try {
        await userSignUp(signUpData);
        navigate("/map");
      } catch (error) {
        console.error("Registration failed:", error);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const login = () => {
    navigate("/login");
  };

  const passwordMessage = !credentials.confirmPassword
    ? ""
    : credentials.password === credentials.confirmPassword &&
      credentials.password
    ? "repeatPasswordSuccess"
    : "repeatPasswordError";

  return (
    <div className={styles.container}>
      <TextField
        placeholder="Логин"
        type="text"
        name="login"
        value={credentials.login}
        onChange={onChange}
      />
      {errors.login && (
        <span style={{ color: "red", marginBottom: "20px" }}>
          {errors.login}
        </span>
      )}
      <TextField
        placeholder="Почта"
        type="email"
        name="email"
        value={credentials.email}
        onChange={onChange}
      />
      {errors.email && (
        <span style={{ color: "red", marginBottom: "20px" }}>
          {errors.email}
        </span>
      )}
      <TextField
        placeholder="Пароль"
        type="password"
        name="password"
        value={credentials.password}
        onChange={onChange}
      />
      {errors.password && (
        <span style={{ color: "red", marginBottom: "20px" }}>
          {errors.password}
        </span>
      )}
      <TextField
        placeholder="Повторите пароль"
        type="password"
        name="confirmPassword"
        value={credentials.confirmPassword}
        onChange={onChange}
      />
      <div style={{ height: "22px", marginBottom: "20px" }}>
        {passwordMessage === "repeatPasswordError" && (
          <span style={{ color: "red" }}>Пароли не совпадают</span>
        )}
        {passwordMessage === "repeatPasswordSuccess" && (
          <span style={{ color: "green" }}>Пароли совпадают</span>
        )}
      </div>
      <div className={styles.rowContainer}>
        <Button title="Регистрация" type="submit" onClick={registration} />
        <div className={styles.login} onClick={login}>
          Уже зарегистрированы? Войти
        </div>
      </div>
    </div>
  );
};

export default RegistrationWindow;
