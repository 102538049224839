import React from "react";
import styles from "./ButtonPlusMinus.module.scss";
import classNames from "classnames";

export type ButtonPlusMinusProps = {
  isOpen: boolean;
  toggleOpen: () => void;
};

export default function ButtonPlusMinus(props: ButtonPlusMinusProps) {
  return (
    <button
      className={classNames(
        styles.plusMinusButton,
        styles[props.isOpen ? "open" : "close"]
      )}
      onClick={props.toggleOpen}
    ></button>
  );
}
