import { memo, useState } from "react";
import styles from "./LayerElement.module.scss";

import ButtonPlusMinus from "../ButtonPlusMinus/ButtonPlusMinus";

export default memo(function ListElement({
  title,
  Content,
}: {
  title: string;
  Content: JSX.Element;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.elemItem}>
      <div className={styles.elementContainer}>
        <ButtonPlusMinus isOpen={isOpen} toggleOpen={toggleDropdown} />
        <div className={styles.titleContainer}>
          <div className={styles.chapter}>{title}</div>
        </div>
      </div>

      {isOpen && <div style={{ paddingTop: 8 }}>{Content}</div>}
    </div>
  );
});
