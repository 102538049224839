import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const BarInfoWindow = ({title, labels, values, barColor} : {title: string, labels: string[], values: number[], barColor: string}) => {

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                  offset: true
                }
            }
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: true,
            color: 'red'
         },
        },
      };

      const data = {
        labels,
        datasets: [
          {
            barPercentage: 0.5,
            barThickness: 26,
            maxBarThickness: 28,
            minBarLength: 2,
            data: values,
            backgroundColor: barColor,
          }
        ],
      };

      return (
        <>
        <div>{title}</div>
        <Bar data={data} options={options}/>
      </>
      );
}

export default BarInfoWindow;
