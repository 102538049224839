import { fromLonLat } from "ol/proj";
import { RControl, RLayerWMS, RMap, ROSM, RLayerTileWebGL, RLayerVectorTile, RLayerTileJSON } from "rlayers";
import ZoomHomeButton from "../MapControls/ZoomHomeButton";
import { AtlasMap } from "./config/interfaces";

import "ol/ol.css";
import { useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import MapLoader from "../Loaders/MapLoader/MapLoader";
import styles from "./Map.module.css";
import { getPost } from "./utils/get_map_component";
import ButtonScreenshot from "../ButtonScreenshot/ButtonScreenshot";
import RLayerVectorMBTiles from "rlayers/layer/RLayerVectorMBTiles";
import { FeatureLike } from "ol/Feature";
import { Style } from "ol/style";
import * as style from "./mbtilesStyle";
import { useConstructorMode } from "../../redux/constructorMode";
import RLayerStadia from "rlayers/layer/RLayerStadia";
import { MVT } from "ol/format";

interface MapInitialInterface {
  map_config: AtlasMap;
}

export function MapMercator(map_configs: MapInitialInterface): JSX.Element {
  const mapRef = useRef<HTMLDivElement>(null);
  const map_config = map_configs.map_config;
  const center = fromLonLat(map_config.center);
  const isOSM = map_config.basemap === "osm";
  const hasLayers = map_config.layers.length > 0;
  const initial = {
    center: center,
    zoom: map_config.zoom.start,
  };
  const [view, setView] = useState(initial);
  const [isLoading, setIsLoading] = useState(true);

  const extent = useSelector((state: any) => state.basemapSlice.bbox);
  const isConstructorMode = useSelector(useConstructorMode);
  const parser = useMemo(() => new MVT(), []);

  return (
    <div>
      {isConstructorMode && <ButtonScreenshot elementRef={mapRef.current} />}
      <ZoomHomeButton onClick={() => setView(initial)} />
      <div ref={mapRef} className={styles.mapWrap}>
        {isLoading && <MapLoader />}
        <RMap
          width={"100%"}
          height={"100%"}
          projection={map_config.projection}
          extent={extent}
          enableRotation={false}
          initial={initial}
          minZoom={map_config.zoom.min}
          maxZoom={map_config.zoom.max}
          noDefaultControls
          view={[view, setView]}
          onRenderComplete={() => setIsLoading(false)}
        >
          <RControl.RZoom className={styles.zoom} />
          {hasLayers ? getPost(map_config) : null}
          {isOSM || isConstructorMode ? (
            <ROSM />
          ) : (
            <RLayerWMS
              url={map_config.basemap}
              params={{
                bgcolor: "0xD3D5DA",
                TRANSPARENT: false,
              }}
            ></RLayerWMS>
            
          )}
        </RMap>
      </div>
    </div>
  );
}
