import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RPopup } from "rlayers";
import { setClusterId } from "../../../../redux/map";
import { openSidebar } from "../../../../redux/menu";
import styles from "./ClusterPopup.module.css";
import { transform } from "proj4";

interface ClusterPopupInterface {
    abbr: string,
    uni_name: string,
    current: any,
    cluster_id: number,
}

export const ClusterPopup = (props: ClusterPopupInterface) => {
  const abbr = props.abbr;
  const uni_name = props.uni_name;
  const current = props.current;
  const cluster_id = props.cluster_id;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clusterFrameSize = [
    "", 
    "100px", //"НГУ"
    "100px", //"ЮФУ"
    "50px", //"СтГАУ"
    "30px", //"ПГУ"
    "200px", //"РГГМУ"
    "50px", //"ВИТИГ"
    "60px", //"КалмГУ"
    "90px", //"ОГУ"
];
  const clusterFrameTransform = [
    "", 
    "translate(0px, 0px)", //"НГУ"
    "translate(0px, 0px)", //"ЮФУ"
    "translate(0px, 15px)", //"СтГАУ"
    "translate(0px, 20px)", //"ПГУ"
    "translate(0px, 0px)", //"РГГМУ"
    "translate(0px, 10px)", //"ВИТИГ"
    "translate(0px, 10px)", //"КалмГУ"
    "translate(0px, 0px)", //"ОГУ"
]

  const clusterFrameClick = (e: any) => {
      dispatch(setClusterId(cluster_id));
      navigate("/map");
      dispatch(openSidebar(true));
  };

  return (
    <>
        <RPopup trigger="hover"
              positioning="center-center"
              onClick={clusterFrameClick}>
                <div className={styles.clusterFrameName}
                style={{transform: clusterFrameTransform[cluster_id]}} 
                >
                <div style={{
                    width: clusterFrameSize[cluster_id], 
                    height: clusterFrameSize[cluster_id]}} 
                    className={styles.clusterFrame}></div>
                <div className={styles.clusterName}>
                  <div className={styles.plashkaClusterAbbr}>{abbr}</div>
                  <div className={styles.plashkaClusterUniName}>{uni_name}</div>
                </div>
                </div>
              </RPopup>
    </>
  );
};