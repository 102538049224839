import React from "react";
import { Routes, Route } from "react-router-dom";
import MapPage from "./pages/MapPage";
import NewsPage from "./pages/NewsPage";
import WelcomePage from "./pages/WelcomePage";
import AboutPage from "./pages/AboutPage";
import DocumentsPage from "./pages/DocumentsPage";
import ContactsPage from "./pages/ContactsPage";
import EditorPage from "./pages/EditorPage";
import LoginPage from "./pages/LoginPage";
import RegPage from "./pages/RegistrationPage";
import { PrivateRoute } from "./PrivateRoute";
import ConstructorPage from "./pages/ConstructorPage";

export default function Routing() {
  type Routes = {
    [key: string]: { path: string; element: JSX.Element };
  };

  const routes: Routes = {
    index: { path: "/", element: <WelcomePage /> },
    login: { path: "/login", element: <LoginPage /> },
    registration: { path: "/registration", element: <RegPage /> },
    news: { path: "/news", element: <NewsPage /> },
    about: { path: "/about", element: <AboutPage /> },
    documents: { path: "/documents", element: <DocumentsPage /> },
    contacts: { path: "/contacts", element: <ContactsPage /> },
    map: { path: "/map", element: <MapPage /> },
    mapById: { path: "/map/:id", element: <MapPage /> },
  };

  const privateRoutes: Routes = {
    addPost: { path: "/edit/map", element: <EditorPage type="addPost" /> },
    editPostById: {
      path: "/edit/map/:id",
      element: <EditorPage type="addPost" />,
    },
    addMapConfig: {
      path: "/edit/layer",
      element: <EditorPage type="addMap" />,
    },
    constructor: {
      path: "/constructor/",
      element: <ConstructorPage />,
    },
    constructorByClusterId: {
      path: "/constructor/:id",
      element: <ConstructorPage />,
    },
  };

  return (
    <Routes>
      {Object.keys(routes).map((route: string) => (
        <Route
          path={routes[route].path}
          element={routes[route].element}
          key={routes[route].path}
        />
      ))}
      <Route element={<PrivateRoute />}>
        {Object.keys(privateRoutes).map((route: string) => (
          <Route
            path={privateRoutes[route].path}
            element={privateRoutes[route].element}
            key={privateRoutes[route].path}
          />
        ))}
      </Route>
    </Routes>
  );
}
