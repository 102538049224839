import GeoJSON from "ol/format/GeoJSON";
import Feature from "ol/Feature";
import { Geometry } from "ol/geom";
import React, { useCallback, useMemo } from "react";
import { RFeature, RLayerVector, RStyle, RPopup } from "rlayers";
import { Layer } from "../../config/interfaces";
import { InfoWindowWihoutMarker } from "./InfowindowCreatorWithoutMarker";
import { getColorByScale } from "../../utils/get_color_by_scale";

interface MapCartogramCreatorInterface {
  layer: Layer;
}

export function MapCartogramCreator(layer_prop: MapCartogramCreatorInterface) {

  const layer = layer_prop.layer;

  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=1000&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });

  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { name: string, type: string, color_hex: string } }[];
  };
  const fetchData = useMemo(() => {
    return fetch(layer_url).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url]);

  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);
  RFeature.hitTolerance = 0;

  const [data, setData] = React.useState({ features: [] } as inputDataType);

  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );

  let classified_prop = "year_2020"
  if (layer.classified_prop) {
    classified_prop = layer.classified_prop;
  };

  // a6caff 6ca2ff 2861f7  0039ce 0028ac
  const renderStyle = useCallback((f: any) => {
    let color = "#ffffff";
    if (layer.scale) {
      color = getColorByScale(f, classified_prop, layer.scale, "#ffffff");
    }
    return (
      <>
      <RStyle.RFill color={color}/>
      <RStyle.RStroke width={0.5} color={'#f5f5f5'}/>
      </>
      ); 
  }, [classified_prop])

  return (
    <>
    <RLayerVector
        zIndex={2}
        format={parser}
        url={layer_url}
        visible={false}
      >
        <RLayerVector
        zIndex={1}
        format={parser}
        url={layer_url}
        onPointerEnter={useCallback((e: any) => setCurrent(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent(null),
          [current]
        )}
      >
        <RStyle.RStyle
          render={renderStyle}
        />
      </RLayerVector>
      </RLayerVector>

      { layer.infowindow ? 
      <RLayerVector zIndex={5}>
        {current ? (
          (<div>
            <RFeature geometry={current.getGeometry()}>
              <RPopup trigger="hover"
                positioning="center-left">
                  <InfoWindowWihoutMarker feature={current.getProperties()} infowindow={layer.infowindow}/>
              </RPopup>
            </RFeature>
          </div>)
        ) : null}
      </RLayerVector> : null }
    </>
  );
}
