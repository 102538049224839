import React, { useEffect, useRef, useState } from "react";
import styles from "./Select.module.scss";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { measureTextWidth, shortenString } from "../../../common/utils";
import { MenuItem } from "../../../types/content-types";

export type SelectProps = {
  options: MenuItem[];
  value: number | null;
  placeholder?: string;
  isDefaultValue?: boolean;
  onSelectedChange: (selected: number) => void;
};

const Select: React.FC<SelectProps> = ({
  options,
  value,
  placeholder,
  isDefaultValue,
  onSelectedChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  useEffect(() => {
    if (!value && isDefaultValue && options.length > 0) {
      onSelectedChange(options[0].id);
    }
  }, [value, isDefaultValue, options, onSelectedChange]);

  const getValueString = () => {
    const tag = options.find((option) => option.id === value)?.tag || "";
    const title = options.find((option) => option.id === value)?.title || "";
    const titleBracket = title !== "" ? ` (${title})` : title;
    const tagWidth = measureTextWidth(tag, "700 15px Nunito Sans");

    return value !== null ? (
      <>
        <span style={{ fontWeight: 700 }}>{tag}</span>
        {shortenString(titleBracket, 354 - tagWidth, "400 15px Nunito Sans")}
      </>
    ) : (
      placeholder
    );
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsInitialRender(false);
  };

  const handleItemSelect = (id: number) => {
    onSelectedChange(id);
    setIsOpen(false);
  };

  return (
    <div className={styles.container} ref={dropdownRef}>
      <div className={styles.selectContainer} onClick={toggleDropdown}>
        <div className={styles.input}>{getValueString()}</div>
        <div className={isOpen ? styles.roundArrowUp : styles.roundArrowDown} />
      </div>
      <div
        className={
          isInitialRender
            ? styles.dropdownInitial
            : isOpen
            ? styles.dropdownOpen
            : styles.dropdown
        }
      >
        <div className={styles.selectContainerOpen} onClick={toggleDropdown}>
          <div className={styles.input}>{getValueString()}</div>
          <div
            className={isOpen ? styles.roundArrowUp : styles.roundArrowDown}
          />
        </div>
        <div className={styles.list}>
          {options.length ? (
            options.map((option, index) => (
              <div
                key={index}
                className={styles.listItem}
                onClick={(event) => {
                  event.stopPropagation();
                  handleItemSelect(option.id);
                }}
              >
                <div
                  className={
                    value === option.id
                      ? styles.itemTitleChecked
                      : styles.itemTitle
                  }
                >
                  <div className={styles.tag}>
                    <span style={{ fontWeight: 700 }}>{option.tag}</span> (
                    {option.title})
                  </div>
                </div>
                <div
                  className={
                    value === option.id ? styles.checked : styles.checkbox
                  }
                />
              </div>
            ))
          ) : (
            <div className={styles.emptyListItem}>Нет элементов</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Select;
