import GeoJSON from "ol/format/GeoJSON";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RFeature, RLayerVector, ROverlay } from "rlayers";
import { Layer } from "../../config/interfaces";
import { MarkerCreator } from "./MarkerCreator";
import { InfoWindowNew } from "./InfowindowCreator";

interface MapPointsCreatorInterface {
  layer: Layer;
}

export function MapPointsCreator(layer_prop: MapPointsCreatorInterface) {

  const layer = layer_prop.layer;

  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=1000&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });

  type inputDataType = {
    features: { 
      id: string,
      geometry: { coordinates: number[] },
      properties: { name: string, type: string, color_hex: string } }[];
  };
  const fetchData = useMemo(() => {
    return fetch(layer_url).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url]);

  const [data, setData] = React.useState({ features: [] } as inputDataType);
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);
  RFeature.hitTolerance = 0;

  const isSelected = useSelector((state: any) => state.selectFeature.isSelected);
  const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

  return (
    <>
    <RLayerVector
        zIndex={2}
        format={parser}
        url={layer_url}
        visible={false}
      >
        {isSelected ?
            <RFeature geometry={
              new Point(fromLonLat([
                selectedFeature.geometry.coordinates[0], 
                selectedFeature.geometry.coordinates[1]
                ]))}>
            <ROverlay 
            positioning="top-left">
              {layer.marker && layer.infowindow ?
                <InfoWindowNew 
                  marker={layer.marker} 
                  infowindow={layer.infowindow}
                  feature={selectedFeature}/>
                : null
              }
              
           </ROverlay>
          </RFeature>
          : 
          <RFeature geometry={new Point(fromLonLat([0, 0]))}>
            <ROverlay positioning="center-left"></ROverlay>
          </RFeature>
           }
        {data.features.map((f) => (
        <RFeature 
          key={f.id}
          geometry={new Point(fromLonLat(f.geometry.coordinates))}
          properties={f.properties}
          >
          <ROverlay 
          positioning="top-left">
            {layer.marker ?
            <MarkerCreator marker={layer.marker} feature={f}/>
          : null}
           </ROverlay>
          </RFeature>
        ))}
      </RLayerVector>
    </>
  );
}
