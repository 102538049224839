import React, { useRef, useState } from "react";
import styles from "./MapControls.module.scss";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { setPostNull } from "../../redux/post";
import { setDefaultMap } from "../../redux/map";
import useOutsideClick from "../../hooks/useOutsideClick";
import MenuButton from "./MenuButton";
import { getTokensFromStorage, removeTokens } from "../../auth/tokenService";
import { useLocation } from "react-router-dom";

export type MenuButtonType = {
  title: string;
  path?: string;
  action: () => void;
};

export default function MapControls() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const tokens = getTokensFromStorage();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: MenuButtonType) => {
    setIsOpen(false);
    option.action();
  };

  const userOptions: MenuButtonType[] = tokens
    ? [
        {
          title: "Пользователь",
          action: () => {
            console.log("Пользователь");
          },
        },
        {
          title: "Выйти",
          action: () => {
            removeTokens();
            navigate("/");
          },
        },
      ]
    : [
        {
          title: "Войти",
          action: () => {
            navigate("/login");
          },
        },
      ];

  const options: MenuButtonType[] = [
    {
      title: "Путеводитель",
      path: "/map",
      action: () => {
        dispatch(setPostNull());
        dispatch(setDefaultMap());
        navigate(`/map`);
      },
    },
    {
      title: "Конструктор",
      path: "/constructor",
      action: () => {
        navigate(`/constructor`);
      },
    },
    {
      title: "Новости",
      path: "/news",
      action: () => {
        navigate(`/news`);
      },
    },
    {
      title: "О нас",
      path: "/about",
      action: () => {
        navigate(`/about`);
      },
    },
  ];

  return (
    <div className={styles.buttonsContainer}>
      <div ref={dropdownRef}>
        <div
          className={
            isOpen ? styles.burgerMenuButtonOpen : styles.burgerMenuButton
          }
          onClick={toggleDropdown}
        />
        <div className={isOpen ? styles.dropdownOpen : styles.dropdownClose}>
          {options.length &&
            options.map((option, index) => (
              <div
                key={index}
                className={styles.listItem}
                onClick={() => handleSelect(option)}
              >
                <div
                  className={
                    option.path && location.pathname.includes(option.path)
                      ? styles.itemTitleChecked
                      : styles.itemTitle
                  }
                >
                  {option.title}
                </div>
                <div
                  className={
                    option.path && location.pathname.includes(option.path)
                      ? styles.checked
                      : styles.checkbox
                  }
                />
              </div>
            ))}
        </div>
      </div>
      <MenuButton
        options={userOptions}
        onSelect={handleSelect}
        type={"userMenu"}
      />
      <div className={styles.searchButton} />
    </div>
  );
}
