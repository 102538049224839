import React, { useCallback, useMemo } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import Feature from "ol/Feature";
import { RLayerVector, RFeature, RStyle} from "rlayers";
import { Layer } from "./config/interfaces";
import { getCurrentRegionData } from "../../common/utils";
import { setClusterId } from "../../redux/map";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { openSidebar } from "../../redux/menu";
import { ClusterPopup } from "./elements/popups/ClusterPopup";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function AtlasLayer(layers: AtlasLayerInterface) {
  // Берем clusterId и  map_config из стора:
  const { clusterId, map_config } = useSelector(
    (state: RootState) => state.map
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // cluster polygon
  const layer = layers.layers[0];
  const layer_url = `https://geo.touristatlas.ru/geoserver/${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  
  const parser = new GeoJSON({ featureProjection: "EPSG:4326" });
  type inputDataType = {
    records: { fields: { name: string } }[];
  };
  const fetchData = useMemo(() => {
    return fetch(layer_url).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url]);
  //const getData = (data: inputDataType, dep: string) =>
  //data.records.find((el) => el.fields.name === dep);
  // The default hitbox around the features is 3px wide making narrow gaps between the borders difficult to select
  RFeature.hitTolerance = 0;
  const [data, setData] = React.useState({ records: [] } as inputDataType);
  const [current, setCurrent] = React.useState(
    null as Feature<Geometry> | null
  );
  React.useEffect(() => {
    fetchData.then((r) => setData(r));
  }, []);

  // cluster line
  const layer_line = layers.layers[1];
  const layer_url_line = `https://geo.touristatlas.ru/geoserver/${layer_line.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer_line.workspace}%3A${layer_line.layer}&maxFeatures=100&outputFormat=application%2Fjson`;
  const [data_line, setDataLine] = React.useState({ records: [] } as inputDataType);
  const parser_line = new GeoJSON({ featureProjection: "EPSG:4326" });
  const fetchDataLine = useMemo(() => {
    return fetch(layer_url_line).then((raw) => raw.json() as Promise<inputDataType>);
  }, [layer_url_line]);
  React.useEffect(() => {
    fetchDataLine.then((r) => setDataLine(r));
  }, []);



  const handleRegionClick = (e: any) => {
    if (e.target) {
      const regionData = getCurrentRegionData(e.target);
      dispatch(setClusterId(regionData?.clusterId));
      navigate("/map");
      dispatch(openSidebar(true));

      // add flyTo
      e.map.getView().fit(e.target.getGeometry().getExtent(), {
        duration: 750,
        maxZoom: 4.2,
      })
    }
  };


  return (
    <>
      <RLayerVector
        zIndex={1}
        format={parser}
        url={layer_url}
        onPointerEnter={useCallback((e: any) => setCurrent(e.target), [])}
        onPointerLeave={useCallback(
          (e: any) => current === e.target && setCurrent(null),
          [current]
        )}
        onClick={handleRegionClick}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (
              <>
                <RStyle.RFill color={`rgba(95, 121, 220, 1)`} />
                <RStyle.RStroke width={0.5} color={'#f5f5f5'}/>
                </>
              ),
            [data]
          )}
        />
      </RLayerVector>
      <RLayerVector
        zIndex={2}
        format={parser_line}
        url={layer_url_line}
      >
        <RStyle.RStyle
          render={useCallback(
            (f: any) => (<RStyle.RStroke width={0.6} color={"#ffffff"}/>),
            [data_line]
          )}
        />
      </RLayerVector>
      { layer.label ? 
      <RLayerVector zIndex={3}>
        {current ? (
          <div>
            <RFeature geometry={current.getGeometry()}>
              <ClusterPopup 
                abbr={current.get('abbr')} 
                uni_name={current.get('university_name')} 
                cluster_id={current.get("cluster_id")} 
                current={current}/>
            </RFeature>
          </div>
        ) : null}
      </RLayerVector> : null }
    </>
  );
}
