import React, { useCallback } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { useSelector, useDispatch } from "react-redux";
import {
  addAbstractLayerToWorkspace,
  removeAbstractLayerFromWorkSpace,
  reorderAbstractLayersInWorkspace,
  reorderLayers,
} from "../../redux/map";
import { RootState, AppDispatch } from "../../store";
import LayerElement from "../LayerElement/LayerElement";
import styles from "./Constructor.module.scss";
import { AbstractLayer } from "../../pages/ConstructorPage";
import ListElement from "../LayerElement/ListElement";
import LinkItem from "../listMenu/LinkItem";

type List = { [key: string]: AbstractLayer[] };

function restructureAbstractLayers(abstractLayers: AbstractLayer[]) {
  const result: List = {};
  abstractLayers.forEach((item) => {
    if (!result[item.chapter_title]) {
      result[item.chapter_title] = [];
    }
    result[item.chapter_title].push(item);
  });
  return result;
}

export default function Constructor() {
  const { abstractLayers } = useSelector((state: RootState) => state.map);
  const { abstractLayersWorkspace } = useSelector(
    (state: RootState) => state.map
  );

  const layersListByChapters = restructureAbstractLayers(abstractLayers);

  const dispatch = useDispatch<AppDispatch>();

  const onSortEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      if (isNaN(newIndex)) return;
      dispatch(
        reorderAbstractLayersInWorkspace({
          startIndex: oldIndex,
          endIndex: newIndex,
        })
      );

      dispatch(reorderLayers({ startIndex: oldIndex, endIndex: newIndex }));
    },
    [dispatch]
  );

  const workspaceTitles = abstractLayersWorkspace.map((obj) => obj.post_title);

  const handleToggleAbstractLayerToWorkspace = (
    isItemInWorkspace: boolean,
    layer: AbstractLayer
  ) => {
    isItemInWorkspace
      ? dispatch(removeAbstractLayerFromWorkSpace(layer.post_title))
      : dispatch(addAbstractLayerToWorkspace(layer));
  };

  return (
    <div className={styles.constructorContainer}>
      <div className={styles.layersListContainer}>
        <div className={styles.title}>База слоев</div>
        {Object.keys(layersListByChapters).map((chapter, index) => (
          <ListElement
            key={index}
            title={chapter}
            Content={
              <>
                {layersListByChapters[chapter].map((layer, index) => {
                  const isItemInWorkspace = workspaceTitles.includes(
                    layer.post_title
                  );

                  return (
                    <LinkItem
                      key={index + layer.post_title}
                      title={layer.post_title}
                      handleClick={() => {
                        handleToggleAbstractLayerToWorkspace(
                          isItemInWorkspace,
                          layer
                        );
                      }}
                      checked={isItemInWorkspace}
                    />
                  );
                })}
              </>
            }
          />
        ))}
      </div>
      <div className={styles.workspaceContainer}>
        <div className={styles.title}>Рабочая область</div>
        <SortableList onSortEnd={onSortEnd} draggedItemClassName="dragged">
          {abstractLayersWorkspace?.map((layer) => (
            <SortableItem key={layer.post_title}>
              <div style={{ zIndex: 2 }}>
                <LayerElement layer={layer} />
              </div>
            </SortableItem>
          ))}
        </SortableList>
      </div>
    </div>
  );
}
