import React, { useEffect, useState } from "react";
import styles from "./WindowAuth.module.scss";
import Button from "../Button/Button";
import { TextField } from "../Input/TextField/TextField";
import { loginWithCredentials, userSignUp } from "../../auth/AuthApiService";
import { useNavigate } from "react-router-dom";
import { IUserSignUpData } from "../../types/user";
import { makeRequest } from "../../common/ApiService";
import CheckElement from "../CheckElement/CheckElement";

function MailCheckModule({
  setStep,
  credentials,
  setCredentials,
}: ModuleProps) {
  const [touched, setTouched] = useState(false);

  const emailNotValid = !credentials.email.match(
    /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
  );

  const checkEmail = async () => {
    if (emailNotValid) return;

    const emailBase64 = btoa(credentials.email);

    const { verified } = await makeRequest<{ verified: boolean }>(
      `/api/users/verify?data=${emailBase64}`,
      {
        method: "post",
      },
      true
    );

    if (!!verified) {
      setStep("login");
    } else {
      setStep("reg");
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.authModule}>
        <div className={styles.title}>Вход / Регистрация</div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldTitle}>Электронная почта </div>
          <TextField
            type="text"
            placeholder="user@mail.com"
            value={credentials.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCredentials((prev) => ({
                ...prev,
                email: e.target.value,
                login: e.target.value,
              }));
            }}
            onBlur={() => {
              if (!touched) {
                setTouched(true);
              }
            }}
            isValid={!emailNotValid || !touched}
            validationMessage="Неправильный формат почты"
          />
        </div>
        <Button
          title="Продолжить"
          size="large"
          onClick={checkEmail}
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
}

function LoginModule({ setStep, credentials, setCredentials }: ModuleProps) {
  const [error, setError] = useState("");
  const [touched, setTouched] = useState(false);
  const navigate = useNavigate();

  const login = async () => {
    const loginResult = await loginWithCredentials(
      credentials.email,
      credentials.password
    );
    if (!credentials.password) {
      setError("Введите пароль");
    } else {
      if (!!loginResult) {
        setError("");
        navigate("/map");
      } else {
        setError("Неверный пароль");
      }
    }
  };

  useEffect(() => {
    if (!credentials.password && touched) {
      setError("Введите пароль");
    } else {
      setError("");
    }
  }, [credentials.password, error, touched]);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.authModule}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Вход</div>
          <button
            className={styles.back}
            onClick={() => {
              setStep("mailcheck");
            }}
          >
            Назад
          </button>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.fieldTitle}>Пароль </div>
          <TextField
            type="password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCredentials((prev) => ({ ...prev, password: e.target.value }));
            }}
            onBlur={() => {
              if (!touched) {
                setTouched(true);
              }
            }}
            isValid={!error}
            validationMessage={error}
          />
        </div>
        <Button
          title="Продолжить"
          size="large"
          onClick={login}
          style={{ width: "100%" }}
        />
        <div className={styles.forgotContainer}>
          <div className={styles.forgotLink}>Забыли пароль?</div>
        </div>
      </div>
    </div>
  );
}

function RegModule({ setStep, credentials, setCredentials }: ModuleProps) {
  const [touched, setTouched] = useState(false);
  const navigate = useNavigate();
  const registration = async () => {
    if (passwordValidation.every((rule) => rule.isValid)) {
      const signUpData: IUserSignUpData = {
        login: credentials.login,
        email: credentials.email,
        password: credentials.password,
      };
      try {
        await userSignUp(signUpData);
        navigate("/map");
      } catch (error) {
        console.error("Registration failed:", error);
      }
    }
  };

  const passwordValidation = [
    {
      validationMessage: "8-32 символа",
      isValid:
        !(credentials.password.length < 8) &&
        !(credentials.password.length > 32),
    },
    {
      validationMessage: "Латинские буквы и цифры",
      isValid:
        /[A-Za-z]/.test(credentials.password) &&
        /[0-9]/.test(credentials.password),
    },
    {
      validationMessage: "Строчные и заглавные буквы",
      isValid:
        /[A-Z]/.test(credentials.password) &&
        /[a-z]/.test(credentials.password),
    },
    {
      validationMessage: "Без пробелов",
      isValid: !/\s/.test(credentials.password),
    },
  ];

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched(true);
    }
    const value = e.target.value;
    if (!/[А-Яа-я]/g.test(value)) {
      setCredentials((prev) => ({
        ...prev,
        password: value,
      }));
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.authModule}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Регистрация</div>
          <button
            className={styles.back}
            onClick={() => {
              setStep("mailcheck");
            }}
          >
            Назад
          </button>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.fieldTitle}>Электронная почта</div>
          <TextField type="text" value={credentials.email} disabled />
          <div className={styles.fieldTitle}>Придумайте пароль</div>
          <TextField
            type="password"
            placeholder="Пароль"
            value={credentials.password}
            onChange={handlePasswordChange}
            onBlur={() => {
              if (!touched) {
                setTouched(true);
              }
            }}
            isValid={
              passwordValidation.every((rule) => rule.isValid) || !touched
            }
          />
          <div>
            {passwordValidation.map((item) => (
              <CheckElement
                key={item.validationMessage}
                title={item.validationMessage}
                status={
                  !touched ? "disabled" : item.isValid ? "success" : "wrong"
                }
              />
            ))}
          </div>
        </div>
        <Button
          title="Продолжить"
          size="large"
          onClick={registration}
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
}

type AuthStep = "reg" | "login" | "mailcheck";
type Credentials = { email: string; password: string; login: string };

type ModuleProps = {
  setStep: React.Dispatch<React.SetStateAction<AuthStep>>;
  credentials: Credentials;
  setCredentials: React.Dispatch<React.SetStateAction<Credentials>>;
};

const Modules = (props: ModuleProps) => ({
  reg: <RegModule {...props} />,
  login: <LoginModule {...props} />,
  mailcheck: <MailCheckModule {...props} />,
});

const WindowAuth: React.FC = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    login: "",
  });
  const [step, setStep] = useState<AuthStep>("mailcheck");

  return Modules({ setStep, credentials, setCredentials })[step];
};
export default WindowAuth;
