import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./DataBlocks.module.scss";
import { ContentBlock, Post } from "../../types/content-types";
import Linear from "../Charts/Linear/Linear";
import TagsComponent from "../Tags/Tags";
import TabsComponent from "../Tabs/Tabs";
import SelectorScale from "../Charts/SelectorScale/SelectorScale";
import SolidGradientScale from "../Charts/SolidGradient/SolidGradient";
import { useDispatch, useSelector } from "react-redux";
import { getMapThunk, toggleLayerVisible } from "../../redux/map";
import { AppDispatch, RootState } from "../../store";
import { checkForNestedField } from "../../common/utils";
import TagsHorizontal from "../TagsHorizontal/TagsHorizontal";
import ImageBlock from "../ImageBlock/ImageBlock";
import Checkbox from "../Checkbox/Checkbox";
import GapScale from "../Charts/GapScale/GapScale";
import SolidScale from "../Charts/SolidScale/SolidScale";
import Header from "../TextComponents/Header";
import SubHeader from "../TextComponents/SubHeader";
import Description from "../TextComponents/Description";
import Paragraph from "../TextComponents/Paragraph";

export const ContentBlockByType = ({
  contentBlock,
  isNested,
}: {
  contentBlock: ContentBlock;
  isNested?: boolean;
}) => {
  if (contentBlock.type) {
    const blocks: { [key: number | string]: JSX.Element } = {
      1: <NestedBlocksBlock contentBlock={contentBlock} />,
      2: <TabsComponent contentBlock={contentBlock} />,
      10: <TextBlock contentBlock={contentBlock} isNested={isNested} />,
      20: <TagsComponent contentBlock={contentBlock} />,
      30: <TagsHorizontal contentBlock={contentBlock} />,
      80: <SolidScale contentBlock={contentBlock} />,
      81: <GapScale contentBlock={contentBlock} />,
      40: <ImageBlock contentBlock={contentBlock} />,
      100: <Linear contentBlock={contentBlock} />,
      101: <Linear contentBlock={contentBlock} />,
      102: <Linear contentBlock={contentBlock} />,
      103: <SelectorScale contentBlock={contentBlock} />,
      104: <SolidGradientScale contentBlock={contentBlock} />,
      0: <></>,
    };
    return blocks[contentBlock.type] ?? <></>;
  }
  return <></>;
};

const Blocks = ({ content_blocks }: { content_blocks: ContentBlock[] }) => {
  return (
    <>
      {content_blocks.map((block: ContentBlock, index) => {
        const paddingBottom = index + 1 === content_blocks.length ? 20 : 10;
        return (
          <div
            className={styles.infoBlock}
            key={`data_block_${index}`}
            style={{ paddingBottom }}
          >
            <ContentBlockByType contentBlock={block} />
          </div>
        );
      })}
    </>
  );
};

const isNestedWithCheckboxes = (content_blocks: ContentBlock[]) => {
  return content_blocks.some((block) => block.layer_id);
};

const BlocksWithNested = ({
  content_blocks,
  mapId,
}: {
  content_blocks: ContentBlock[];
  mapId?: string | number;
}) => {
  const isChecklist = isNestedWithCheckboxes(content_blocks);

  const layers = useSelector(
    (state: RootState) => state.map.map_config?.layers
  );

  const [active, setActive] = useState<number>();

  const dispatch = useDispatch<AppDispatch>();
  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number[]>([]);

  const [openIndexes, setOpenIndexes] = useState(new Set());

  const isLayerChecked = useCallback(
    (layer_id?: string) => {
      return (
        layers?.find((layer) => layer.layer === layer_id)?.visible || false
      );
    },
    [layers]
  );

  const handleCheckboxToggle = useCallback(
    (layerId: string | undefined, value: boolean) => {
      if (!layerId) return;
      dispatch(toggleLayerVisible({ layerId, value }));
    },
    [dispatch]
  );

  const handleNestedToggle = useCallback(
    (index: number) => {
      if (active === index) {
        setActive(undefined);
        if (!!mapId) dispatch(getMapThunk(Number(mapId)));
        return;
      }
      setActive(index);
      const activeMapId = content_blocks[index].map_id;

      if (!!activeMapId) dispatch(getMapThunk(Number(activeMapId)));
    },
    [active, content_blocks, dispatch, mapId]
  );

  const handleNestedToggleWithChecklist = useCallback((index: number) => {
    setOpenIndexes((prev) => {
      const updatedItems = new Set(prev);
      if (updatedItems.has(index)) {
        updatedItems.delete(index);
      } else {
        updatedItems.add(index);
      }
      return updatedItems;
    });
  }, []);

  const maxHeight = useCallback(
    (index: number) => {
      if (isChecklist) {
        return openIndexes.has(index) ? height[index] : 0;
      }
      return active === index ? height[index] : 0;
    },
    [active, height, isChecklist, openIndexes]
  );

  useEffect(
    function dynamicHeight() {
      const imgElements = document.querySelectorAll("img");

      const handleReady = () => {
        const heightArray: number[] = [];
        content_blocks.forEach((_, index) => {
          const element = ref.current?.querySelector(
            `#data_nested_${index} > div`
          );
          if (element) {
            heightArray.push(element.clientHeight);
          }
        });
        setHeight(heightArray);
      };

      const handleMediaLoad = () => {
        const allLoaded = Array.from(imgElements).every(
          (element) => element.complete
        );
        if (allLoaded) {
          handleReady();
        }
      };

      if (Array.from(imgElements).length === 0) {
        handleReady();
      } else {
        imgElements.forEach((element) => {
          element.addEventListener("load", handleMediaLoad);
        });
        return () => {
          imgElements.forEach((element) => {
            element.removeEventListener("load", handleMediaLoad);
          });
        };
      }
    },
    [content_blocks]
  );

  return (
    <div ref={ref}>
      {content_blocks.map((block: ContentBlock, index) => (
        <div className={styles.blocksWithNested} key={`data_block_${index}`}>
          <div className={styles.blocksWithNestedTitleContainer}>
            <div
              className={styles.blocksWithNestedTitle}
              onClick={() =>
                isChecklist
                  ? handleNestedToggleWithChecklist(index)
                  : handleNestedToggle(index)
              }
            >
              {isChecklist && (
                <div className={styles.checkboxContainer}>
                  <Checkbox
                    checked={isLayerChecked(block.layer_id)}
                    setChecked={() => {
                      handleCheckboxToggle(
                        block.layer_id,
                        !isLayerChecked(block.layer_id)
                      );
                    }}
                  />
                </div>
              )}
              <span>{block.nested?.[0].header}</span>
            </div>
            <div
              className={
                active === index ? styles.detailsIconUp : styles.detailsIconDown
              }
            />
          </div>
          <div
            id={`data_nested_${index}`}
            className={styles.nestedContent}
            style={{
              maxHeight: maxHeight(index),
              transitionDuration: `${height[index] * 1.5}ms`,
            }}
          >
            <ContentBlockByType contentBlock={block} />
          </div>
        </div>
      ))}
    </div>
  );
};

function DataBlocks({ data }: { data: Post | null }) {
  const content_blocks = data?.content_blocks ?? [];

  const hasNested = checkForNestedField(content_blocks);

  return (
    <>
      {hasNested ? (
        <BlocksWithNested
          content_blocks={content_blocks}
          mapId={data?.map_id}
        />
      ) : (
        <Blocks content_blocks={content_blocks} />
      )}
    </>
  );
}

function NestedBlocksBlock({ contentBlock }: { contentBlock: ContentBlock }) {
  return (
    <div>
      {contentBlock.nested?.map((nestedBlock, nestedIndex: number) => {
        if (
          nestedIndex === 0 &&
          !nestedBlock.sub_header &&
          !nestedBlock.paragraph &&
          !nestedBlock.description
        ) {
          return <div style={{ paddingBottom: 10 }} key={nestedIndex} />;
        }

        return (
          <div key={nestedIndex} className={styles.nestedBlock}>
            <ContentBlockByType contentBlock={nestedBlock} isNested />
          </div>
        );
      })}
    </div>
  );
}

function TextBlock({
  contentBlock,
  isNested,
}: {
  contentBlock: ContentBlock;
  isNested?: boolean;
}) {
  return (
    <>
      {!isNested && contentBlock.header && (
        <Header text={contentBlock.header} />
      )}
      {contentBlock.sub_header && <SubHeader text={contentBlock.sub_header} />}
      {contentBlock.description && (
        <Description text={contentBlock.description} />
      )}
      {contentBlock.paragraph && <Paragraph text={contentBlock.paragraph} />}
    </>
  );
}

export default DataBlocks;
