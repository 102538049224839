import { Layer } from "../../config/interfaces";
import { MapPointsCreator, MapPolygonCreator, MapLineCreator, 
  MapCartogramCreator, MapPieCreator } from "./index";

interface AtlasLayerInterface {
  layers: Layer[];
}

export function MapCreator(layers: AtlasLayerInterface) {
  const all_layers = layers.layers;

  const getLayerComponent = (layer: Layer) => {
    if (layer.geometry_type === "points") {
      return <MapPointsCreator layer={layer}/>
    }
    if (layer.geometry_type === "polygon") {
      return <MapPolygonCreator layer={layer}/>
    }
    if (layer.geometry_type === "line") {
      return <MapLineCreator layer={layer}/>
    }
    if (layer.geometry_type === "cartogram") {
      return <MapCartogramCreator layer={layer}/>
    }
    if (layer.geometry_type === "pie") {
      return <MapPieCreator layer={layer}/>
    }
    else {
      return null;
    }
  }

  return (
    <>
        {all_layers.map((layer) => (
          layer.visible ? getLayerComponent(layer) : null
        ))}
    </>
  );
}
