import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { select, deselect } from "../../../../redux/map/featureReducer";
import { setModalState } from "../../../../redux/modal";
import PopUp from "../../../PopUp/PopUp";
import styles from "../../elements/diagrams/RectangleDiagramsTest.module.css";
import { InfoWindow, Marker} from "../../config/interfaces";
import { getPopupData } from "../../utils";

interface InfowindowCreatorInterface {
    marker: Marker,
    infowindow: InfoWindow,
    feature: any, //fix it!
}

export const InfoWindowNew = (props: InfowindowCreatorInterface) => {
  const [isShown, setIsShown] = useState(false);

  const marker = props.marker;
  const infowindow = props.infowindow;
  const feature = props.feature;

  const popupData = getPopupData(infowindow, feature.properties);


  const dispatch = useDispatch();

  let {
    width,
    height,
    background_color,
    value_color,
  } = marker;

  let color = "#ff0000"
  if (value_color) {
    color = value_color;
  }

  let diagrammSizeforLabel = width;

  if (width < 12 && height < 12) {
    width = 12;
    height = 12;
  }

  if (width < 28) {
    diagrammSizeforLabel = 28;
  }

  if (width > 400 && height > 400) {
    width = 250;
    height = 250;
  }

  const handleMouseEnter = () => {
    setIsShown(true);
    dispatch(select());
  };

  const handleMouseLeave = () => {
    setIsShown(false);
    dispatch(deselect());
  };

  const handleModalOpen = (title: string, text: string) => {
    dispatch(
      setModalState({
        isVisible: true,
        title: title,
        content: <p> {text} </p>,
      })
    );
  };

  
  const readMore = () => {
    if (infowindow.modal_paragraph) {
        handleModalOpen(feature.properties[infowindow.title], feature.properties[infowindow.modal_paragraph]);
    }
    else {
      handleModalOpen('', '');
    }
  }
  

  return (
    <div
      className={styles.rectangleDiagrammFull}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <div
        style={{
          width: `${diagrammSizeforLabel}px`,
          height: `${diagrammSizeforLabel}px`,
          backgroundColor: `${feature.properties[background_color]}`,
          color: `${color}`,
        }}
        className={styles.rectangleDiagramFrame}
      >
      </div>
      {infowindow.modal ?
      <PopUp popupData={popupData} onClick={readMore} />
      :
      <PopUp popupData={popupData} />
      }
    </div>
  );
};
