import React from "react";
import styles from "./LinkElement.module.scss";
import classNames from "classnames";

export default function LinkItem({
  title,
  handleClick,
  accessedLink = true,
  checked,
}: {
  title: string;
  accessedLink?: boolean;
  handleClick?: () => void;
  checked?: boolean;
}) {
  return (
    <div
      className={styles.mapLinkContainer}
      onClick={handleClick ? () => handleClick() : undefined}
    >
      <div
        className={classNames(
          accessedLink ? styles.mapLinkIcon : styles.lockLinkIcon,
          {
            [styles.checked]: checked,
          }
        )}
      />
      <div
        className={accessedLink ? styles.mapLink : styles.lockLink}
        style={{ fontWeight: 700 }}
      >
        {title}
      </div>
    </div>
  );
}
