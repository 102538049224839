import BaseStyles from "../components/common.module.scss";
import Navbar from "../components/Navbar/Navbar";
import WindowAuth from "../components/WindowAuth/WindowAuth";

export default function LoginPage() {
  return (
    <>
      <div className={BaseStyles.splashScreen} />
      <Navbar activePage={"Авторизация"} />
      <WindowAuth />
    </>
  );
}
