import { AbstractLayer, MenuItem } from "./../../types/content-types";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { getPostsListThunk } from "../menu";
import {
  getConstructorBaseMap,
  getConstructorLayers,
  getMap,
  getMapsList,
} from "../../common/ApiService";
import { AtlasMap, Layer } from "../../components/Map/config/interfaces";

import { clusters } from "../../components/Map/config/Clusters";

const reorder = function <T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function getLayersFormData(layers: AbstractLayer[]) {
  if (!layers) return [];
  return layers.reduce((acc: Layer[], curr) => {
    // acc.push(...curr.map_layers);
    const layer = { ...curr.map_layers[0], visible: false };

    acc.push(layer);
    return acc;
  }, []);
}

type State = {
  clusterId: number | null;
  chapterId: number | null;
  map_config: AtlasMap | null;
  isMapLoading: boolean;
  mapsList: MenuItem[];
  abstractLayers: AbstractLayer[];
  abstractLayersWorkspace: AbstractLayer[];
};

const initialState: State = {
  clusterId: null,
  chapterId: null,
  map_config: null,
  isMapLoading: false,
  mapsList: [],
  abstractLayers: [],
  abstractLayersWorkspace: [],
};

type LayerTogglePayload = {
  layerId: string;
  value: boolean;
};

type LayersTogglePayload = {
  layerIds: string[];
  value: boolean;
};

export const setClusterId = createAction<number | null>("SET_CLUSTER_ID");
export const setChapterId = createAction<number | null>("SET_CHAPTER_ID");

export const setDefaultMap = createAction("SET_DEFAULT_MAP");

export const toggleLayerVisible = createAction<LayerTogglePayload>(
  "TOGGLE_LAYER_VISIBLE",
);

export const getMapThunk = createAsyncThunk("map/get", getMap);

export const getConstructorBaseMapThunk = createAsyncThunk(
  "constructorMap/get",
  getConstructorBaseMap,
);

export const getConstructorLayersThunk = createAsyncThunk(
  "constructorLayers/get",
  getConstructorLayers,
);

export const getConstructorActiveMapById = createAsyncThunk(
  "constructorMapById/get",
  getMap,
);

export const getMapsListThunk = createAsyncThunk("map/getList", getMapsList);

export const toggleMultipleLayersVisible = createAction<LayersTogglePayload>(
  "TOGGLE_MULTIPLE_LAYERS_VISIBLE",
);

export const reorderLayers = createAction<{
  startIndex: number;
  endIndex: number;
}>("REORDER_LAYERS");
export const reorderAbstractLayersInWorkspace = createAction<{
  startIndex: number;
  endIndex: number;
}>("REORDER_ABSTRACT_LAYERS");

export const addAbstractLayerToWorkspace =
  createAction<AbstractLayer>("ADD_ABSTRACT_LAYER");

export const removeAbstractLayerFromWorkSpace = createAction<string>(
  "REMOVE_ABSTRACT_LAYER",
);

const mapReducer = createReducer(initialState, (builder) => {
  builder.addCase(setClusterId, (state, action) => {
    state.clusterId = action.payload;
    getPostsListThunk({
      clusterId: action.payload!,
      chapterId: state.chapterId!,
    });
  });
  builder.addCase(setChapterId, (state, action) => {
    state.chapterId = action.payload;
    getPostsListThunk({
      clusterId: state.clusterId!,
      chapterId: action.payload!,
    });
  });
  builder.addCase(setDefaultMap, (state) => {
    state.map_config = clusters;
  });
  builder.addCase(getMapThunk.fulfilled, (state, action) => {
    state.map_config = action.payload;
    state.isMapLoading = false;
  });
  builder.addCase(getMapThunk.pending, (state) => {
    state.isMapLoading = true;
  });

  builder.addCase(getConstructorBaseMapThunk.fulfilled, (state, action) => {
    state.map_config = action.payload;
    state.isMapLoading = false;
  });
  builder.addCase(getConstructorBaseMapThunk.pending, (state) => {
    state.isMapLoading = true;
  });

  builder.addCase(getConstructorLayersThunk.fulfilled, (state, action) => {
    state.abstractLayers = action.payload;
    state.isMapLoading = false;
  });
  builder.addCase(getConstructorLayersThunk.pending, (state) => {
    state.isMapLoading = true;
  });

  builder.addCase(getConstructorActiveMapById.fulfilled, (state, action) => {
    if (state.map_config) {
      state.map_config.layers = action.payload.layers;
    }
    state.isMapLoading = false;
  });
  builder.addCase(getConstructorActiveMapById.pending, (state) => {
    state.isMapLoading = true;
  });

  builder.addCase(getMapsListThunk.fulfilled, (state, action) => {
    state.mapsList = action.payload;
    state.isMapLoading = false;
  });
  builder.addCase(getMapsListThunk.pending, (state) => {
    state.isMapLoading = true;
  });
  builder.addCase(toggleLayerVisible, (state, action) => {
    const { layerId, value } = action.payload;
    const layer = state.map_config?.layers.find(
      (layer) => layer.layer === layerId,
    );
    if (layer) {
      layer.visible = value;
    }
  });

  builder.addCase(toggleMultipleLayersVisible, (state, action) => {
    const { layerIds, value } = action.payload;
    const layers = state.map_config?.layers.filter(
      (layer) => layerIds?.includes(layer.layer),
    );
    if (layers?.length) {
      layers.forEach((layer) => (layer.visible = value));
    }
  });

  builder.addCase(reorderLayers, (state, action) => {
    if (state.map_config) {
      state.map_config.layers = reorder(
        state.map_config.layers,
        action.payload.startIndex,
        action.payload.endIndex,
      );
    }
  });
  builder.addCase(reorderAbstractLayersInWorkspace, (state, action) => {
    if (state.abstractLayersWorkspace) {
      state.abstractLayersWorkspace = reorder(
        state.abstractLayersWorkspace,
        action.payload.startIndex,
        action.payload.endIndex,
      );
    }
  });

  builder.addCase(addAbstractLayerToWorkspace, (state, action) => {
    if (
      !state.abstractLayersWorkspace.find(
        (layer) => layer.post_title === action.payload.post_title,
      )
    ) {
      state.abstractLayersWorkspace.push(action.payload);
      if (state.map_config) {
        const layers = [
          ...state.map_config.layers,
          { ...action.payload.map_layers[0], visible: false },
        ];
        state.map_config.layers = layers;
      }
    }
  });
  builder.addCase(removeAbstractLayerFromWorkSpace, (state, action) => {
    const filteredLayers = state.abstractLayersWorkspace.filter(
      (layer) => layer.post_title !== action.payload,
    );
    state.abstractLayersWorkspace = filteredLayers;
  });
});

export default mapReducer;
